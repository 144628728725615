<template>
  <div>
    <table class="table table-striped">
      <tbody>
        <tr v-for="u in userRoutes" :key="u.id">
          <td>{{ u.user.first_name }} {{ u.user.last_name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    userRoutes: {
      required: true,
      type: Array,
    },
  },
  data: () => {
    return {};
  },
};
</script>